<template>
  <div class="bot">
    <div class="top">
      <div class="juzhong">
        <div class="inb">
          <div class="spa marr">
            <img src="../assets/qixingp/gongsi@2x.png" alt="" />
            <span>公司名称：青岛七星国际会展科技有限公司</span>
          </div>
          <div class="spa">
            <img src="../assets/qixingp/telephone@2x.png" alt="" />
            <span>联系电话：4009660622</span>
          </div>
          <div class="spa">
            <img src="../assets/qixingp/dizhi@2x.png" alt="" />
            <span>公司地址：青岛市市北区福州北路159号昕苑丽都</span>
          </div>
          <!-- <div class="spa">
            <span>电话：{{ tableData.phone1 }}</span>
          </div> -->
        </div>
        <div class="shuxian"></div>
        <div class="inb">
          <span>友情链接：</span>
          <a href="http://qdjiapeng.com/" target="_blank">
            青岛佳朋展览展示公司
          </a>
          <!-- <a href="http://zhanju365.com/" target="_blank">
            青岛玛斯特装饰设计工程
          </a> -->
          <!-- <a href="https://www.yuangtech.com/#/home" target="_blank">
            青岛元罡科技有限公司
          </a> -->
        </div>
        <div class="shuxian"></div>
        <div class="inb">
          <!-- <div class="in_block">
            <p>共享展具小程序</p>
            <img src="@/assets/qixingp/矩形 942小程序二维码.png" alt="" />
          </div> -->
          <div class="in_block">
            <p>七星公众号</p>
            <img src="@/assets/qixingp/gongzhonghao@2x.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">
        Copyright© 青岛七星国际会展科技有限公司 版权所有
        备案号：鲁ICP备15022445号
      </a>
    </div>
  </div>
</template>

<script>
//import x from ''
export default {
  data() {
    return {
      page: 1,
      total: 0,
      tableData: "",
      list: "",
      title: {
        top: "展览设计搭建",
        bottom: "一站式展会设计搭建服务，助力企业打造展会亮点",
      },
    };
  },
  created() {
    this.getData();
  },
  methods: {
    handleCurrentChange(val) {
      this.page = val;
      this.getData();
    },

    toDetail(id) {
      this.$router.push({ path: "/exhibitionDetail_s", query: { item: id } });
    },
    getData() {
      const that = this;
      const data = {};
      that.$http
        .postApi("/qx_company_phone", data)
        .then((res) => {
          if (res.data.code === 200) {
            console.log(res);
            that.tableData = res.data.data;
            console.log("电话", that.tableData);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.$bus.$emit("setTit", this.title);
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
// 底部
.top {
  width: 100%;
  background: #252f35;
  padding: 34px 0;
  .xian {
    display: inline-block;
    width: 2px;
    height: 103px;
    background: #aaaaaa;
    margin-left: 21px;
    margin-right: 62px;
    &:nth-child(4) {
      margin-left: 60px;
    }
  }
  .juzhong {
    width: 1100px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    .inb {
      display: inline-block;
      .in_block {
        display: inline-block;
        &:nth-child(2) {
          margin-left: 46px;
        }
        img {
          width: 120px;
          height: 120px;
          margin-top: 13px;
        }
      }
      .spa {
        margin-top: 24px;
        display: flex;
        align-items: center;
        // justify-content: center;
        img {
          width: 14px;
          height: 18px;
        }
        span {
          font-size: 14px;
          font-family: Microsoft YaHei, Microsoft YaHei-Regular;
          font-weight: 400;
          text-align: left;
          color: #ffffff;
          margin-left: 17px;
        }
      }
      span {
        font-size: 14px;
        font-family: Microsoft YaHei, Microsoft YaHei-Regular;
        font-weight: 400;
        text-align: left;
        color: #ffffff;
        margin-left: 17px;
      }
      a {
        margin-top: 24px;
        display: block;
        font-size: 14px;
        font-family: Microsoft YaHei, Microsoft YaHei-Regular;
        font-weight: 400;
        text-align: left;
        color: #ffffff;
        margin-left: 17px;
      }
      p {
        font-size: 14px;
        font-family: Microsoft YaHei, Microsoft YaHei-Regular;
        font-weight: 400;
        text-align: left;
        color: #ffffff;
        text-align: center;
      }
    }
  }
}
.bottom {
  padding: 18px 0;
  a {
    font-size: 12px;
    font-family: Microsoft YaHei, Microsoft YaHei-Regular;
    font-weight: 400;
    text-align: left;
    color: #ffffff;
    background: #121d25;
    text-align: center;
  }
}
.bottom {
  padding: 18px 0;
  font-size: 12px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  text-align: left;
  color: #ffffff;
  background: #121d25;
  text-align: center;
}
.marr {
  margin-top: 0;
}
.shuxian {
  width: 1px;
  height: 103px;
  background: #aaaaaa;
  margin: 0 50.5px;
}
</style>
